<template>
<div class="fit">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless>
        <b-tab class="p-0">
            <template slot="title">
                <div class="text-red font-weight-bold pt-1">
                    <q-icon size="1rem" color="red" name="delete" />&nbsp;
                    {{translate('delete_user')}}
                </div>
            </template>
            <b-list-group class="list-group-accent">
                <b-list-group-item>
                    <q-item class="p-0">
                        <q-item-section avatar class="p-0">
                            <q-avatar class="ml-2 shadow-2">
                                <img v-if="user.image_link" class="black-and-white" :src="user.image_link">
                                <i v-else class="fas fa-user fa-1x text-primary"></i>
                            </q-avatar>
                        </q-item-section>
                        <q-item-section class="full-height align-self-center pt-2 pb-2">
                            <strong class="text-muted">{{ user.name }}</strong>
                        </q-item-section>
                    </q-item>
                    <q-item class="p-0">
                        <q-item-section avatar class="p-0">
                            <q-avatar class="ml-2 shadow-2">
                                <i class="fas fa-envelope fa-1x text-primary"></i>
                            </q-avatar>
                        </q-item-section>
                        <q-item-section class="full-height align-self-center pt-2 pb-2">
                            <strong class="text-muted">{{ user.email }}</strong>
                        </q-item-section>
                    </q-item>
                    <q-item class="p-0">
                        <q-item-section avatar class="p-0">
                            <q-avatar class="ml-2 shadow-2">
                                <i class="fas fa-user-cog fa-1x text-primary"></i>
                            </q-avatar>
                        </q-item-section>
                        <q-item-section class="full-height align-self-center pt-2 pb-2">
                            <strong class="text-muted">{{ translate(user.user_type) }}</strong>
                        </q-item-section>
                    </q-item>
                </b-list-group-item>
                <b-list-group-item>
                    <q-item class="p-0">
                        <q-item-section>
                            <q-item-label line="1">{{translate('delete_user_intention')}}</q-item-label>
                            <q-item-label line="2">{{translate('deleting_user')}}</q-item-label>
                        </q-item-section>
                    </q-item>
                </b-list-group-item>
                <b-list-group-item>
                    <q-item class="p-0 full-width">
                        <q-item-section side>
                            <q-btn no-caps @click="showDeleteSecondConfirmationPanel" type="submit" color="danger">
                                <q-icon class="mr-1" size="1rem" name="check" /> {{translate('yes_continue')}}
                            </q-btn>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn no-caps @click.prevent="closePanel" type="button" color="secondary" class="text-dark">
                                <q-icon class="mr-1" size="1rem" name="cancel" /> {{translate('no')}}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </b-list-group-item>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import { eventBus } from '../../../../main'
import DeleteUserSecondConfirmation from './DeleteUserSecondConfirmation'

export default {
    name: 'DeleteUser',
    props: ['user'],
    methods: {
        showDeleteSecondConfirmationPanel() {
            const panelInstance = this.$showPanel({
                component: DeleteUserSecondConfirmation,
                props: {
                    user: this.user,
                }
            })
        },
        closePanel() {
            this.$emit('closePanel');
        }
    }
}
</script>
