<template>
<div class="container">
    <br>
    <b-card>
        <div slot="header">
            <h3>
                <strong>{{translate('delete_profile_image')}}</strong>
            </h3>
        </div>
        <p>{{translate('deleting_profile_image')}}</p>
        <b-row>
            <b-col>
                <b-btn variant="danger" @click="deleteProfileImage">{{translate('yes')}}</b-btn>
            </b-col>
            <b-col class="text-right">
                <b-button @click.prevent="closePanel" type="button" variant="light">{{translate('no')}}</b-button>
            </b-col>
        </b-row>

    </b-card>
</div>
</template>

<script>
import { eventBus } from '../../main'

export default {
    name: 'DeleteProfileImage',
    props: ['delete_profile_image_route', 'user_id'],
    methods: {
        deleteProfileImage: function () {
            var url = this.delete_profile_image_route
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }

            axios.delete(url, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + key: ''
                    var message = 'delete_profile_image_' + response.data.msg + key
                    var translated_message = this.translate(message)

                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: response.data.status
                        })
                        eventBus.$emit('updateCurrentUserProfileImage', { id: this.user_id })
                    } else {
                        if (response.data.msg === 'cannot_delete_file') {
                            this.$emit('closePanel', {
                                status: true
                            })
                            translated_message = this.translate(response.data.msg) + '_with_status_true'
                            this.$toasted.success(translated_message, {
                                duration: this.$toasted_duration
                            })
                        } else {
                            this.$toasted.error(translated_message, {
                                duration: this.$toasted_duration
                            })
                        }
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        closePanel: function () {
            this.$emit('closePanel')
        }
    }
}
</script>
