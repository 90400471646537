<template>
    <div class="profile-picture text-center" :class="classes">
        <img v-if="src" class="full-width" :src="src">
        <i v-else class="fa fa-photo fa-2x no-image"></i>
    </div>
</template>

<script>
export default {
    name: 'ProfilePicture',
    props: ['src', 'container_class'],
    computed: {
        classes: function () {
            var classes = ''
            if (this.container_class) {
                classes = this.container_class
            }
            return classes
        }
    },
}
</script>

<style lang="scss" scoped>
.profile-picture {
    border: 2px solid #fff;
    background-clip: border-box;
    overflow: hidden;
    box-shadow: 0 0 6px rgba(0, 0, 0, .36);

    &.border-radius-drop-perc-10 {
        border-radius: 0px 10%;
    }
    &.border-radius-drop-perc-20 {
        border-radius: 0px 20%;
    }
    &.border-radius-drop-perc-30 {
        border-radius: 0px 30%;
    }
    &.border-radius-drop-perc-40 {
        border-radius: 0px 40%;
    }
    &.border-radius-drop-perc-50 {
        border-radius: 0px 50%;
    }

    .no-image {
        line-height: 100px;
    }
}
</style>
