<template>
<default-container>
    <q-card slot="page-content" class="mb-3 mb-md-4">
        <b-row class="full-width">
            <b-col sm="12" md="2" class="bg-light p-3 p-md-4">
                <profile-picture :src="current_user.image_link"/>
                <q-item tag="label" clickable class="mt-2 p-2 bg-positive text-white" v-ripple style="min-height: 0">
                    <q-item-section class="p-0" side>
                        <q-icon class="mr-1" size="1.5rem" color="light" name="fas fa-photo"></q-icon>
                    </q-item-section>
                    <q-item-section class="p-0 text-center">
                        <small>{{ translate('add_image') }}</small>
                    </q-item-section>
                    <q-item-section class="p-0 text-right" side>
                        <q-icon size="1rem" color="light" name="fas fa-upload"></q-icon>
                        <b-form-file class="hidden" type="file" name="selectedFile" @change="onFileSelected" v-model="selectedFile"></b-form-file>
                    </q-item-section>
                </q-item>
                <q-item tag="label" clickable class="p-2 bg-negative text-white" v-ripple style="min-height: 0" @click.stop="showDeleteProfileImagePanel()">
                    <q-item-section class="p-0" side>
                        <q-icon class="mr-1" size="1.5rem" color="light" name="fas fa-photo"></q-icon>
                    </q-item-section>
                    <q-item-section class="p-0 text-center">
                        <small>{{ translate('delete_image') }}</small>
                    </q-item-section>
                    <q-item-section class="p-0 text-right" side>
                        <q-icon size="1rem" color="light" name="delete"></q-icon>
                    </q-item-section>
                </q-item>
            </b-col>
            <b-col sm="12" md="10" class="p-3 p-md-4 bg-white">
                <q-item v-if="!$q.screen.lt.sm" class="full-width p-0 mb-2">
                    <q-item-section>
                        <small class="text-muted mt-xs-2">{{ translate('my_profile') }}</small>
                        <div class="text-h6">{{ translate('personal_data') }}</div>
                    </q-item-section>
                    <q-item-section side>
                        <q-btn no-caps color="danger" type="button" @click="showDeleteAccountPanel">
                            <q-icon size="1.25rem" name="delete" class="mr-1" /> {{ translate('delete_account') }}
                        </q-btn>
                    </q-item-section>
                    <q-item-section side>
                        <q-btn no-caps color="primary" type="button" @click="showEditPanel">
                            <i class="fas fa-edit mr-1"></i> {{ translate('edit') }}
                        </q-btn>
                    </q-item-section>
                </q-item>
                <q-item v-if="$q.screen.lt.sm" class="full-width p-0 mb-2">
                    <q-item-section>
                        <q-btn class="full-width" size="0.75rem" no-caps color="danger" type="button" @click="showDeleteAccountPanel">
                            <q-icon size="1.25rem" name="delete" class="mr-1" /> {{ translate('delete_account') }}
                        </q-btn>
                    </q-item-section>
                    <q-item-section>
                        <q-btn class="full-width" size="0.75rem" no-caps color="primary" type="button" @click="showEditPanel">
                            <i class="fas fa-edit mr-1"></i> {{ translate('edit') }}
                        </q-btn>
                    </q-item-section>
                </q-item>
                <q-item v-if="$q.screen.lt.sm" class="full-width p-0 mb-2">
                    <q-item-section>
                        <small class="text-muted mt-xs-2">{{ translate('my_profile') }}</small>
                        <div class="text-h6">{{ translate('personal_data') }}</div>
                    </q-item-section>
                </q-item>
                <q-separator />
                <q-item class="full-width mt-2 p-0">
                    <q-item-section>
                        <span class="text-muted">{{ translate('first_name') }}</span>
                        {{ current_user.first_name }}
                    </q-item-section>
                </q-item>
                <q-item class="full-width mt-2 p-0">
                    <q-item-section>
                        <span class="text-muted">{{ translate('last_name') }}</span>
                        {{ current_user.last_name }}
                    </q-item-section>
                </q-item>
                <q-item class="full-width mt-2 p-0">
                    <q-item-section>
                        <span class="text-muted">{{ translate('gender') }}</span>
                        {{ current_user.gender === 'f' ? translate('gender_female') : translate('gender_male') }}
                    </q-item-section>
                </q-item>
                <q-item class="full-width mt-2 p-0">
                    <q-item-section>
                        <span class="text-muted">{{ translate('age') }}</span>
                        {{ current_user_age }}
                    </q-item-section>
                </q-item>
                <q-item class="full-width mt-2 p-0">
                    <q-item-section>
                        <span class="text-muted">{{ translate('birth_date') }}</span>
                        {{ prettyDate(current_user.birth_date) }}
                    </q-item-section>
                </q-item>
                <q-item class="full-width mt-2 p-0">
                    <q-item-section>
                        <span class="text-muted">{{ translate('phone_number') }}</span>
                        {{ current_user.phone_number }}
                    </q-item-section>
                </q-item>
                <q-item class="full-width mt-2 p-0">
                    <q-item-section>
                        <span class="text-muted">{{ translate('email') }}</span>
                        {{ current_user.email }}
                    </q-item-section>
                </q-item>
            </b-col>
        </b-row>
    </q-card>
</default-container>
</template>

<script>
import _ from 'lodash'
import { eventBus } from '../../../main'
import DefaultContainer from '../../../containers/DefaultContainer'
import EditCurrentUser from './forms/EditCurrentUser'
import ProfilePicture from '../../template-parts/ProfilePicture'
import DeleteProfileImage from '../../template-parts/DeleteProfileImage'
import DeleteUser from '../../user-management/users/forms/DeleteUser'

export default {
    name: 'MyProfile',
    components: {
        'default-container': DefaultContainer,
        'profile-picture': ProfilePicture,
    },
    created: function () {
        eventBus.$on('updateCurrentUser', (item) => {
            this.getCurrentUser(item.id)
        })
        eventBus.$on('updateCurrentUserProfileImage', (item) => {
            this.updateRemoveProfileImage()
        })
        this.$on('transition', this.getCurrentUser())
    },
    computed: {
        delete_profile_image_route: function () {
            if (this.current_user_type === 'worker') {
                return baseUrl + 'profile_images/worker/' + this.current_user.worker_id
            }
            if (this.current_user_type === 'customer') {
                return baseUrl + 'profile_images/customer/' + this.current_user.customer_id
            }
        },
        put_route: function () {
            if (this.current_user_type === 'worker') {
                return baseUrl + 'workers'
            }
            if (this.current_user_type === 'customer') {
                return baseUrl + 'customers'
            }
        },
        delete_route: function () {
            if (this.current_user_type === 'worker') {
                return baseUrl + 'workers'
            }
            if (this.current_user_type === 'customer') {
                return baseUrl + 'customers'
            }
        },
        current_user_type: function () {
            return this.$appSettings.currentUserType()
        },
        image_upload_route: function () {
            if (this.current_user_type === 'worker') {
                return baseUrl + 'profile_images/worker'
            }
            if (this.current_user_type === 'customer') {
                return baseUrl + 'profile_images/customer'
            }
        },
        post_route: function () {
            return baseUrl + 'users/'
        },
        current_user_age: function () {
            var age = ''
            if (this.current_user && this.current_user.birth_date) {
                var age_milliseconds = Date.now() - new Date(this.current_user.birth_date).getTime()
                var age_seconds = age_milliseconds / 1000
                var year_seconds = (60 * 60 * 24 * 365)

                var current_month = new Date().getMonth()
                var current_date = new Date().getDate()
                age = parseInt(age_seconds / year_seconds)
                if (current_month == new Date(this.current_user.birth_date).getMonth() && current_date < new Date(this.current_user.birth_date).getDate()) {
                    age = parseInt(age_seconds / year_seconds) - 1
                }
            }
            return age
        },
    },
    data: function () {
        return {
            selectedFile: null,
            current_user: {
                phone_number: '-',
            },
        }
    },
    methods: {
        updateRemoveProfileImage: function () {
            this.current_user.image_link = null
        },
        onFileSelected: function (event) {
            this.selectedFile = event.target.files[0]
            this.selectedFile.image_name = this.selectedFile.name.split(' ').join('_')
            this.uploadImage()
        },
        uploadImage: function () {
            const formData = new FormData()
            var file_name = this.selectedFile.name.split(' ').join('_')
            formData.append('file', this.selectedFile, file_name)
            if (this.current_user_type === 'worker') {
                formData.set('worker_id', this.current_user.worker_id)
            }
            if (this.current_user_type === 'customer') {
                formData.set('customer_id', this.current_user.customer_id)
            }

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'multipart/form-data'
            }
            axios.post(this.image_upload_route, formData, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'images_' + response.data.msg + key
                    var translated_message = this.translate(message)
                    
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        eventBus.$emit('updateCurrentUserProfileImage', { id: this.customer_id })
                        this.selectedFile = null
                        // Update image.
                        this.getCurrentUser()
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }

                })
                .catch(error => {
                    // console.log(error)
                })
        },
        getCurrentUser: function (id) {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken()
            }
            var url = baseUrl + 'users/current'
            
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    if (response.data.item) {
                        this.current_user = response.data.item
                        this.current_user.image_link = response.data.item.image_link ? baseUrl + response.data.item.image_link : null
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        showDeleteProfileImagePanel: function () {
            const panelInstance = this.$showPanel({
                component: DeleteProfileImage,
                props: {
                    delete_profile_image_route: this.delete_profile_image_route,
                    user_id: this.current_user.worker_id ? this.current_user.worker_id : this.current_user.customer_id
                }
            })
        },
        showDeleteAccountPanel: function () {
            this.current_user.name = this.current_user.first_name + ' ' + this.current_user.last_name
            this.current_user.user_type = this.current_user_type

            const panelInstance = this.$showPanel({
                component: DeleteUser,
                props: {
                    user: this.current_user
                }
            })
        },
        showEditPanel: function () {
            const panelInstance = this.$showPanel({
                component: EditCurrentUser,
                props: {
                    put_route: this.put_route
                }
            })

            panelInstance.promise
                .then(result => {
                    this.getCurrentUser()
                })
        },
    }
}
</script>
